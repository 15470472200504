@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

/*
* Buttons
* ================ */

// Base
.btn {
	display: inline-block;
	text-align: center;
	// line-height: 1;
	line-height: inherit;
	white-space: nowrap;
	max-width: 100%;
	text-overflow: ellipsis;
	cursor: pointer;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	text-decoration: none;
	text-transform: uppercase;
  margin: 10px;
  .brk-bordered-theme & {
    border-radius: var(--b-radius);
  }
}

.btn-shadow {
  box-shadow: 0 5px 10px -2px var(--brk-base-1) !important;
}

.btn-shadow-small {
  box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), .5);

	.brk-safari & {
		box-shadow: 0 5px 16px var(--brk-base-6);
	}
}

.btn-dark-shadow {
	box-shadow: 0 5px 10px -2px rgba(0,0,0, .4) !important;
}

.btn-dark-shadow-small {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
}

.btn-no-shadow {
  box-shadow: none !important;
}

.invert,
.invert .bsk-btn {
	color: $white !important;
}

.btn-min-width-200{
  min-width: 200px;
}

.btn-min-width-110{
  min-width: 110px;
}

.btn-xs-max-width{
  @media #{$sm-max}{
    width: 100%;
  }
}



/* SIZES BTN */
.btn-sm {
	padding: 4px 22px;
	font-size: 13px;

	i.icon-inside {
		font-size: 13px;
	}

	&.btn-inside-out > .before {
		padding-top: 4px;
	}

	&.btn-simple {
		min-width: 130px;
	}
}

.btn-sm-1 {
	padding: 8px 26px;
	font-size: 13px;

	i.icon-inside {
		font-size: 13px;
	}

	&.btn-inside-out > .before {
		padding-top: 8px;
	}

	&.btn-simple {
		min-width: 130px;
	}
}

.btn-sm-2 {
	padding: 7px 22px;
	font-size: 14px;

	i.icon-inside {
		font-size: 14px;
	}

	&.btn-inside-out > .before {
		padding-top: 7px;
	}

	&.btn-simple {
		min-width: 110px;
	}
}

.btn-md {
	padding: 10px 33px;
	font-size: 14px;
	i.icon-inside {
		font-size: 14px;
	}
	&.btn-inside-out > .before {
		padding-top: 10px;
	}
}

.btn-md-1 {
	padding: 10px 33px;
	letter-spacing: 3.5px;
	min-height: 44px;
	font-size: 14px;
	i.icon-inside {
		font-size: 14px;
	}
	&.btn-inside-out > .before {
		padding-top: 10px;
	}
}

.btn-md-2 {
	padding: 10px 33px;
	font-size: 16px;
	i.icon-inside {
		font-size: 16px;
	}
	&.btn-inside-out > .before {
		padding-top: 12px;
	}
}

.btn-md-3 {
  padding: 9px 22px 9px 26px;
  font-size: 14px;
}

.btn-md-4 {
  font-size: 12px;
  i.icon-inside {
		font-size: 12px;
	}
}

.btn-md-5 {
  font-size: 10px;
  padding: 12px 33px;
  i {
    font-size: 14px;
  }
}

.btn-lg {
	padding: 15px 33px;
	font-size: 16px;
	i.icon-inside {
		font-size: 16px;
	}
	&.btn-inside-out > .before {
		padding-top: 15px;
  }
}

.btn-lg-1 {
  padding: 13px 33px;
  font-size: 12px;

  i.icon-inside {
    font-size: 12px;
  }
  &.btn-inside-out > .before {
		padding-top: 13px;
  }
}

.btn-xl {
	padding: 17px 42px;
	font-size: 19px;
	i.icon-inside {
		font-size: 19px;
	}
	&.btn-inside-out > .before {
		padding-top: 17px;
	}
}
/* SIZES BTN */


.btn__full_width {
	width: 100%;
}

.btn [class*="fa-"].icon-left {
	margin: 0 0 0 10px;
	position: relative;
	z-index: 3;
}

.border-radius {
	&-50 {
		border-radius: 50px;
	}
	&-30 {
    border-radius: 30px;
		&.btn-prime .border-btn {
			border-radius: 30px;
		}
	}
	&-25 {
		border-radius: 25px;
		&.btn-prime .border-btn {
			border-radius: 25px;
		}
	}
	&-10 {
		border-radius: 10px;
		&.btn-prime .border-btn {
			border-radius: 10px;
		}
	}
	&-5 {
		border-radius: 5px;
		&.btn-prime .border-btn {
			border-radius: 5px;
		}
	}
	&-0 {
		border-radius: 0;
		&.btn-prime .border-btn {
			border-radius: 0;
		}
  }
}

// Btn Styles
.btn-box {
	& + & {
		margin-top: 49px;
	}
	text-align: center;
}


.btn {
  .brk-bordered-theme & {
		border-radius: var(--b-radius);
		&.btn-prime .border-btn {
			border-radius: var(--b-radius);
		}
	}
	&:focus {
		box-shadow: none;
		color: #fff;
	}

	[class*="fa-"] {
		margin-right: 10px;
		vertical-align: baseline;

		[dir="rtl"] & {
			margin-right: 0;
			margin-left: 10px;
		}
	}

	&-icon {
		&:before, &:after {
			padding-left: 10px;
		}
		[class*="fa-"] {
			position: relative;
			color: $white;
			font-size: 16px;
			z-index: 1;
    }
  }

  &-icon-right{
    i{
      position: absolute !important;
      right: 20px;
      margin-right: 0 !important;
      top: 50%;
      transform: translateY(-50%);
    }
    &:before, &:after {
			padding-left: 0;
		}
  }

	&.btn-icon-abs [class*="fa-"] {
		position: absolute;
		top: 50%;
		left: -16px;
		transform: translateY(-50%);
		font-size: 48px;
		color: #e0e0ff;
		opacity: .3;
		z-index: 1;
	}

	/*&:hover {
		&.btn-icon [class*="fa-"]:before {
			color: $gray-dark;
		}
	}*/
}

.btn-simple {
	font-size: 24px;
	position: relative;
	background: $white;
	color: var(--brand-primary);
	box-shadow: 0 5px 16px 0 rgba($black, 0.2);
	padding: 10px 15px;
	min-width: 110px;
	vertical-align: middle;
	line-height: 16px;

	&:hover {
		color: $white !important;
		background: var(--brand-primary);
	}

	i.icon-inside {
		font-size: 24px;
	}

	[class*="fa-"]:not(.icon-inside) {
		margin-right: 0;
		font-size: 24px;
		font-weight: 700;
	}

	&.btn-circle {
		min-width: 0;
		width: 52px;
		height: 52px;
		color: var(--brand-primary) !important;

		> .before, > .after {
			display: none;
		}

		&:hover {
			background: var(--brk-base-4) !important;

			.text {
				color: var(--brand-primary) !important;
			}
		}

		i.icon-inside {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			[dir="rtl"] & {
				margin-left: 0;
			}

			&:before {
				line-height: 24px;
				vertical-align: top;
			}
    }
    
    &.btn-md {
      width: 44px;
      height: 44px;
    }
	}
}

.btn-inside-out {
	overflow: hidden;
  border: none;
	transition: background .4s ease .3s, \box-shadow .4s;
  will-change: background;

	&_dark {
		background: #272727 !important;

		&:hover {
			background: var(--brk-base-4) !important;
			color: #272727 !important;

			i {
				color: #272727 !important;
			}
		}

		> .before,
		> .after {
			background: #272727 !important;
		}
  }

	&_text-dark {
		color: #272727 !important;

		&.btn-inside-out-invert.btn-icon [class*="fa-"]:before {
			color: #272727;
		}

		> .before,
		> .after {
			color: #272727 !important;
		}
	}

	&_blue {
		background: rgb(0, 2, 67) !important;

		&:hover {
			background-color: var(--brk-base-1) !important;
			color: #ffffff;
		}

		> .before,
		> .after {
			background: rgb(0, 2, 67) !important;
		}
  }

	.icon-inside {
		margin-right: 8px;

		[dir="rtl"] & {
			margin-right: 0;
			margin-left: 8px;
		}
	}
	.icon-inside ~ .before, .icon-inside ~ .after {
		padding-left: 24px;

		[dir="rtl"] & {
			padding-left: 0;
			padding-right: 24px;
		}
  }
  
  &.btn-icon-right {
    .icon-inside ~ .before, 
    .icon-inside ~ .after,
    .icon-inside ~ .text {
      padding-right: 20px;
      padding-left: 0;
  
      [dir="rtl"] & {
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }

	> .before, > .after {
		position: absolute;
		width: 100%;
		height: 50%;
		left: 0;
		overflow: hidden;
		background: var(--brand-primary);
		color: $white;
		transition: \transform 0.3s;
	}

	> .before {
		top: 0;
    padding-top: 8px;
	}
	> .after {
		bottom: 0;
    line-height: 0;
	}

	> span.text {
		display: inline-block;
    transform: scale(0.1);
    will-change: transform;
		opacity: 0;
		color: #ffffff;
		transition: \transform 0.3s, opacity 0.3s;
	}

	&:hover {
    background: var(--brk-base-1);
		transition: background .4s;
		transition-delay: 0s;
    color: #fff;
		box-shadow: 0 5px 10px -2px var(--brk-base-1);
		> .before {
      transform: translateY(-105%);
		}
		> .after {
      transform: translateY(105%);
		}
		> span.text {
			opacity: 1;
			transform: scale(1);
    }
    &.btn-shadow {
      box-shadow:  0 5px 10px -2px rgba(var(--brk-base-1-rgb), .8) !important;
    }
    &.btn-shadow-small {
      box-shadow: 0 5px 16px rgba(var(--brk-base-1-rgb), .8) !important;
    }
    &.btn-dark-shadow {
      box-shadow:  0 5px 10px -2px rgba(var(--brk-base-1-rgb), .8) !important;      
    }
    &.btn-dark-shadow-small {
      box-shadow: 0 5px 16px rgba(var(--brk-base-1-rgb), .8) !important;
    }
  }
}

.btn-inside-out-invert {
	border: none;
	box-shadow: 0 5px 16px rgba(0,0,0,.15);

	> .before, > .after {
		background: $white;
		color: var(--brand-primary);
	}

	&:hover {
		&.btn-icon [class*="fa-"]:before,
		span.text {
			color: #fff;
		}
	}

	span.text {
		color: var(--brand-primary);
	}
	&.btn-icon [class*="fa-"]:before {
		color: var(--brand-primary);
  }
}

.btn-inside-out-invert-light {
	border: none;
	//box-shadow: 0 5px 21px rgba(var(--brand-primary-rgb), 0.3);
	box-shadow: 0 5px 21px var(--brk-base-1);

	> .before, > .after {
		background: $white;
		color: var(--brand-primary);
	}

	&:hover {
		&.btn-icon [class*="fa-"]:before {
			color: var(--brand-primary);
    }
    background-color: rgba(256,256,256, .9);
	}

	span.text {
		color: var(--brand-primary);
	}
	&.btn-icon [class*="fa-"]:before {
		color: var(--brand-primary);
  }
}

.btn-inside-out-light-hover{
  &:hover{
    background-color: #ffffff;
		color: #222222;
  }
}

.btn-prime {
	color: var(--brand-primary);
	background: $white;
	border: none;
	box-shadow: 0 5px 16px rgba(0,0,0,.2);
	position: relative;
	overflow: hidden;
	z-index: 1;

	.border-btn {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		border: 2px solid var(--brand-primary);
		z-index: -1;
	}

	> .before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 0;
		background: var(--brand-primary);
		transition: background .4s, height .4s;
		z-index: -1;
	}

	> .after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 0;
		background: var(--brand-primary);
		transition: background .4s, height .4s;
		z-index: -1;
	}

	&:hover {
		color: var(--brand-primary) !important;

		> .before,
		> .after {
			height: 50%;
			background: var(--brk-base-4) !important;
		}
	}

	&-white {
		> .before, > .after {
			background: $white;
		}
		.border-btn {
			border-color: $white;
		}
		&:hover {
			color: var(--brand-primary) !important;
		}
  }
  &-white-transparent{
    &:hover> .before,
    &:hover> .after {
			background: $white !important;
		}
    .border-btn{
      border-color: $white;
    }
    background: transparent;
    color: $white;
  }
  
  &-primary-transparent{
    &:hover> .before, 
    &:hover> .after {
			background: $white !important;
		}
    .border-btn{
      border-color: var(--brand-primary);
    }
    background: transparent;
    color: $white;
  }

  &-primary-text-transparent{
    &:hover> .before, 
    &:hover> .after {
			background: $white !important;
		}
    .border-btn{
      border-color: var(--brand-primary);
    }
    background: transparent;
    color: var(--brand-primary);
  }

	&.btn-invert{
		color: $white;

		> .before, > .after {
			background: transparent;
		}
		.border-btn {
			border-color: var(--brk-base-2);
		}
  }
  
  &_thin {
    .border-btn {
      border-width: 1px;
    }
  }
}

.all-light .btn-prime {
	background: transparent;
	color: $white;
	box-shadow: 0 5px 16px rgba(0,0,0,.3);

	&:hover {
		[class*="fa-"].icon-left {
			color: $white;
		}
	}

	[class*="fa-"].icon-left {
		color: var(--brand-primary);
		font-size: 24px;
		vertical-align: sub;
		float: right;
		line-height: 1;
		margin: -2px 6px 0 0;
	}
}

.btn-gradient {
	background: var(--brand-primary);
	overflow: hidden;
	position: relative;
	appearance: none;
  border: none;
  color: #fff;
  --x:190px;
  --y:30px;
	--size: 200px;

  transition: background .3s;
	span,
	i {
	  z-index: 1;
	  position: relative;
	  pointer-events: none;
	}


	&::before {
	  content: '';
	  position: absolute;
	  left: var(--x);
	  top: var(--y);
	  width: var(--size);
	  height: var(--size);
	  background: radial-gradient(circle closest-side, var(--secondary), transparent);
	  transform: translate(-50%, -50%);
	  transition: width .2s, height .2s;
	  z-index: 0;
  }
  &:hover{
    color: #ffffff;
  }
	&:hover::before {
	  --size: 200px;
	}
}

.btn-pos {
	color: $gray-dark;
	background: #F6F6F6;
	overflow: hidden;
	position: relative;

	span {
		position: absolute;
		left: 0;
		display: block;
		width: 0;
		height: 0;
		border-radius: 50%;
		background-color: var(--brand-primary);
		transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
		transform: translate(-50%, -50%);
		z-index: -1;
	}

	&:hover {
		color: $white;

		span {
			width: 250%;
			height: 250%;
		}
	}
}

// Icon btns
.icon__btn {
	display: inline-block;
	margin: 10px;
	border-radius: 50%;
	color: $white;
	background: var(--brand-primary);
	position: relative;
	z-index: 3;
  .brk-bordered-theme & {
    border-radius: var(--b-radius);
  }

	.spike {
		position: absolute;
		top: 8px;
		left: 8px;
		right: 8px;
		bottom: 8px;
		background: var(--brand-primary);
		z-index: -1;
		&:nth-child(1) {
			transform: rotate(0deg);
		}
		&:nth-child(2) {
			transform: rotate(10deg);
		}
		&:nth-child(3) {
			transform: rotate(20deg);
		}
		&:nth-child(4) {
			transform: rotate(30deg);
		}
		&:nth-child(5) {
			transform: rotate(40deg);
		}
		&:nth-child(6) {
			transform: rotate(50deg);
		}
		&:nth-child(7) {
			transform: rotate(60deg);
		}
		&:nth-child(8) {
			transform: rotate(70deg);
		}
		&:nth-child(9) {
			transform: rotate(80deg);
		}
		&:nth-child(10) {
			transform: rotate(90deg);
		}
	}

	> .before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transform: scale(1.35);
		opacity: .1;
		background-color: var(--brand-primary);
		border-radius: 50%;
    z-index: -1;
    .brk-bordered-theme & {
    border-radius: var(--b-radius);
  }
	}

	> .after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: .2;
		background-color: var(--brand-primary);
		border-radius: 50%;
    z-index: -1;
    .brk-bordered-theme & {
    border-radius: var(--b-radius);
  }
  }

  &_clear{
    > .before{
      transform: scale(1);
    }
  }

	&:hover {
		color: $white;
		> .before {
			animation: 1.5s brk-pulse .3s infinite;
		}
		> .after {
			animation: brk-pulse 1.5s infinite;
		}
	}

	&-circled {
		> .before {
			top: -33px;
			left: -33px;
			right: -33px;
			bottom: -33px;
			transform: scale(1);
		}
		> .after {
			top: -16px;
			left: -16px;
			right: -16px;
			bottom: -16px;
		}
		&:hover {
			> .before {
				animation: 1s brk-pulse-paused .2s ease-in infinite;
			}
			> .after {
				animation: brk-pulse-paused 1s ease-in infinite;
			}
    }
    &_active {
      > .before {
				animation: 1s brk-pulse-paused .2s ease-in infinite;
			}
			> .after {
				animation: brk-pulse-paused 1s ease-in infinite;
			}
    }
	}

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&-invert{
		background: $white;
		color: var(--brand-primary);
		> .bg {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: -1;
      border-radius: 100%;
			background: $white ;
      .brk-bordered-theme & {
        border-radius: var(--b-radius);
      }
		}
		&:hover{
			background: $white;
			color: var(--brand-primary);
		}
	}
	&-white{
		background: $white;
		color: var(--brand-primary);
		>.before{
            background-color: $white;
        }
        >.after{
            background-color:$white;
        }
		&:hover{
			background: $white;
			color: var(--brand-primary);
		}
	}

	&-sm {
		font-size: 14px;
		width: 41px;
		height: 41px;
	}

	&-md {
		font-size: 16px;
		width: 52px;
		height: 52px;
	}

	&-md_1 {
		font-size: 26px;
		width: 70px;
		height: 70px;
	}

	&-lg {
		font-size: 21px;
		width: 68px;
		height: 68px;
	}

	&-xl {
		font-size: 21px;
		width: 86px;
		height: 86px;
	}

	&-center {
		[class*="fa-"] {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			text-align: center;
		}
	}

	&.icon__btn-svg {
		background: transparent !important;
	}
}

@keyframes brk-pulse {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(2);
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes brk-pulse-paused {
	0% {
		transform: scale(1);
	}
	20% {
		transform: scale(1.2);
	}
	60% {
		transform: scale(0.9);
		opacity: 0;
	}
	100% {
		transform: scale(1);
	}
}

@keyframes lightning {
	0% {
		left: -100px;
		z-index: 3;
	}
	50% {
		left: 300%;
	}
	100% {
		left: -100px;
		z-index: 0;
	}
}

@-webkit-keyframes lightning {
	0% {
		left: -100px;
		z-index: 3;
	}
	50% {
		left: 100%;
	}
	100% {
		left: -100px;
		z-index: 0;
	}
}

@keyframes wave {
	0% {
		margin-left: 0;
	}
	100% {
		margin-left: -9999px;
	}
}

// Dropdown
.btn__dropdown {
  text-align: center;
	&_wrap {
		position: relative;
		display: inline-block;
		padding-right: 41px;
		list-style: none;
		min-width: 170px;
		padding-left: 0;
		margin-bottom: 0;
		overflow: hidden;
		transition: all 300ms;// cubic-bezier(0.250, 0.460, 0.450, 0.940);
    .brk-bordered-theme & {
      border-radius: var(--b-radius);
    }
		&:not(.open) {
			height: 41px;
		}
		&:hover {
			cursor: pointer;
		}
		&.btn__primary {
			background-color: var(--brand-primary);
		}
		&.btn__white {
			background-color: $white-dopdown;
			color: var(--brand-primary);
			//box-shadow: 0 5px 21px rgba(var(--brand-primary-rgb), 0.3);
			box-shadow: 0 5px 21px var(--brk-base-1);
		}
		&:before {
			position: absolute;
      font-family: 'Font Awesome\ 5 Free';
			font-size: 13px;
			content: '\f0dd';
			top: calc(50% - 2px);
			right: 19px;
			line-height: 1;
      transform: translateY(-50%);
      font-weight: 600;
		}

		&.open {
			transition: all 300ms;// cubic-bezier(0.550, 0.085, 0.680, 0.530);
			&:before {
				content: '\f0de';
				top: 50%;
			}
		}

		button:hover, button:focus {
			background: none !important;
		}
	}

	&_item {
		border-bottom: 1px solid;
		border-right: 1px solid;
		padding-left: 18px;
		text-align: left;
		// background: var(--brand-primary);

		.btn__primary & {
			border-color: var(--brk-base-3);
			background: var(--brand-primary);

			a, button {
				color: #ffffff;

				&:hover {
					color: #ffffff;
				}
			}
		}
		.btn__white & {
			border-color: $white-dropdown_border-color;
			background: $white;

			a, button {
				color: var(--brand-primary);

				&:hover {
					color: var(--brand-primary);
				}
			}
		}
		&:first-child {
			padding-top: 2px;
		}
		&:last-child {
			border-bottom: none;
		}
	}

	&_btn {
		text-transform: uppercase;
		font-weight: inherit;
		text-align: left;
		font-size: 14px;
		padding: 9px 9px;
		margin: 0;
		border: none;
		background-color: transparent;
		.btn__dropdown_item & {
			box-shadow: none;
		}
	}
}

.margin-box {
	&__btn-round {
		margin: 25px 0;
		@media(min-width: 768px) {
			margin: 0;
		}
	}

	&__btn-dropdown {
		margin-top: 30px;
		margin-bottom: 1em;

		@media(min-width: 768px) {
			margin-bottom: 63px;
		}

		@media(max-width: 767px) {
			.col-lg-3:first-child & {
				margin-top: 0;
			}
		}
	}
}

.btn--square{
	border: 2px solid var(--brand-primary);
	border-radius: 0;
	padding: 12px 42px;
	overflow: hidden;
	margin: 0;

	.angle{
		width: 10px;
		height: 300%;
		position: absolute;
		display: block;
		transform: translate(-50%, -50%) rotate(-45deg);
		top: 0;
		left: 100%;
		transition: top .2s, left .2s;
	}

	&:hover{
		.angle{
			top: 3%;
			left: 97%;
		}
	}
}

.btn-backgrounds {
	display: inline-block;
	min-width: 260px;
	height: 56px;
	border-radius: 28px;
	background-color: $white;
	position: relative;
	letter-spacing: -0.06em;
	padding-right: 25px;
	font-size: rem(13);
  line-height: 56px;

	.before {
		position: absolute;
		top: 50%;
		right: 7px;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		border-radius: 50%;
		padding-right: 23px;
		letter-spacing: -0.06em;

		.brk-bordered-theme &{
			border-radius: var(--b-radius);
		}

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			vertical-align: 0;
			font-size: rem(18);
		}
	}

  .brk-bordered-theme &{
    border-radius: var(--b-radius);
  }

	&_wauto {
		min-width: 1px;
		white-space: nowrap;
	}

	&_md {
		height: 46px;
		line-height: 46px;
	}

	&_280 {
		min-width: 280px;
	}

	&_200 {
		min-width: 200px;
	}

	&_white {
		//box-shadow: 0 10px 15px rgba(0, 0, 0, .07);
		box-shadow: 0 5px 16px rgba(0,0,0,.2);
		color: #272727;

		.before {
			background-color: #eee;
		}
	}

	&_dark {
		box-shadow: 0 0 26px rgba(0, 0, 0, .3);
		color: $white;

		.before {
			border: solid 2px rgba(255, 255, 255, .4);

			i {
				color: $white;
			}
		}
  }

	&_dark-classic {
		background-color: var(--brand-primary);
		color: #ffffff;
		overflow: hidden;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			background-color: rgba(#fff, .1);
			height: 50%;
			z-index: 0;
		}

		&:before {
			top: -100%;
			transition: top .3s;
		}

		&:after {
			bottom: -100%;
			transition: bottom .3s;
		}

		.text {
			position: relative;
			z-index: 2;
		}

		&:hover {
			color: #ffffff;

			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}

		&:focus,
		&:active {
			color: #ffffff;
		}

		.before {
			width: 34px;
			height: 34px;
			background-color: rgba(#000, .1);
			z-index: 2;
			
			i {
				font-size: rem(15);
			}
		}
	}

	&_white-classic {
		background-color: #fff;
		color: #272727;
		overflow: hidden;

		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			background-color: rgba(#000, .05);
			height: 50%;
			z-index: 0;
		}

		&:before {
			top: -100%;
			transition: top .3s;
		}

		&:after {
			bottom: -100%;
			transition: bottom .3s;
		}

		.text {
			position: relative;
			z-index: 2;
		}

		&:hover {
			color: #272727;

			&:before {
				top: 0;
			}
			&:after {
				bottom: 0;
			}
		}

		&:focus,
		&:active {
			color: #272727;
		}

		.before {
			width: 34px;
			height: 34px;
			background-color: rgba(#000, .1);
			z-index: 2;

			i {
				font-size: rem(15);
			}
		}
	}
  
  &_transparent {
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    border: 1px solid rgba(256,256,256,.16);

    .before {
      background-color: #fff;
       
      i {
        color: var(--brand-primary);
      }
    }

    &:hover {
      color: #fff;
      text-decoration: underline;
    }

  }

  &_left-icon {
    padding-right: 0;
    padding-left: 25px;

    .before {
      right: auto;
      left: 7px;
    }
  }

  &_right-icon {
    padding-right: 25px;
    padding-left: 0;
  }

  &_no-icon {
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
  }
}

.btn-bottom-position {
	position: absolute;
	left: 50%;
	bottom: 0;
	transform: translate(-50%, 50%);
	margin: 0;
}

.btn-violet {
	font-size: rem(12);
	box-shadow: 0 25px 50px rgba(0,0,0,.1) !important;

	&:hover {
		background: rgba(#7202BB, .05);
	}

	.before,
	.after {
		padding-left: 0 !important;
		padding-right: 10px;
		color: var(--secondary);
	}

	.text {
		color: var(--secondary) !important;
		margin-right: 10px;
	}

	i {
    position: absolute !important;
		top: 50%;
		right: 20px;
		margin-right: 0 !important;
		transform: translateY(-50%);

		&:before {
			color: var(--secondary) !important;
		}
	}
}