/*
* Buttons
* ================ */
.btn {
  display: inline-block;
  text-align: center;
  line-height: inherit;
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  cursor: pointer;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  text-decoration: none;
  text-transform: uppercase;
  margin: 10px; }
  .brk-bordered-theme .btn {
    border-radius: var(--b-radius); }

.btn-shadow {
  box-shadow: 0 5px 10px -2px var(--brk-base-1) !important; }

.btn-shadow-small {
  box-shadow: 0 5px 16px rgba(var(--brand-primary-rgb), 0.5); }
  .brk-safari .btn-shadow-small {
    box-shadow: 0 5px 16px var(--brk-base-6); }

.btn-dark-shadow {
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.4) !important; }

.btn-dark-shadow-small {
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1); }

.btn-no-shadow {
  box-shadow: none !important; }

.invert,
.invert .bsk-btn {
  color: #fff !important; }

.btn-min-width-200 {
  min-width: 200px; }

.btn-min-width-110 {
  min-width: 110px; }

@media screen and (max-width: 767px) {
  .btn-xs-max-width {
    width: 100%; } }

/* SIZES BTN */
.btn-sm {
  padding: 4px 22px;
  font-size: 13px; }
  .btn-sm i.icon-inside {
    font-size: 13px; }
  .btn-sm.btn-inside-out > .before {
    padding-top: 4px; }
  .btn-sm.btn-simple {
    min-width: 130px; }

.btn-sm-1 {
  padding: 8px 26px;
  font-size: 13px; }
  .btn-sm-1 i.icon-inside {
    font-size: 13px; }
  .btn-sm-1.btn-inside-out > .before {
    padding-top: 8px; }
  .btn-sm-1.btn-simple {
    min-width: 130px; }

.btn-sm-2 {
  padding: 7px 22px;
  font-size: 14px; }
  .btn-sm-2 i.icon-inside {
    font-size: 14px; }
  .btn-sm-2.btn-inside-out > .before {
    padding-top: 7px; }
  .btn-sm-2.btn-simple {
    min-width: 110px; }

.btn-md {
  padding: 10px 33px;
  font-size: 14px; }
  .btn-md i.icon-inside {
    font-size: 14px; }
  .btn-md.btn-inside-out > .before {
    padding-top: 10px; }

.btn-md-1 {
  padding: 10px 33px;
  letter-spacing: 3.5px;
  min-height: 44px;
  font-size: 14px; }
  .btn-md-1 i.icon-inside {
    font-size: 14px; }
  .btn-md-1.btn-inside-out > .before {
    padding-top: 10px; }

.btn-md-2 {
  padding: 10px 33px;
  font-size: 16px; }
  .btn-md-2 i.icon-inside {
    font-size: 16px; }
  .btn-md-2.btn-inside-out > .before {
    padding-top: 12px; }

.btn-md-3 {
  padding: 9px 22px 9px 26px;
  font-size: 14px; }

.btn-md-4 {
  font-size: 12px; }
  .btn-md-4 i.icon-inside {
    font-size: 12px; }

.btn-md-5 {
  font-size: 10px;
  padding: 12px 33px; }
  .btn-md-5 i {
    font-size: 14px; }

.btn-lg {
  padding: 15px 33px;
  font-size: 16px; }
  .btn-lg i.icon-inside {
    font-size: 16px; }
  .btn-lg.btn-inside-out > .before {
    padding-top: 15px; }

.btn-lg-1 {
  padding: 13px 33px;
  font-size: 12px; }
  .btn-lg-1 i.icon-inside {
    font-size: 12px; }
  .btn-lg-1.btn-inside-out > .before {
    padding-top: 13px; }

.btn-xl {
  padding: 17px 42px;
  font-size: 19px; }
  .btn-xl i.icon-inside {
    font-size: 19px; }
  .btn-xl.btn-inside-out > .before {
    padding-top: 17px; }

/* SIZES BTN */
.btn__full_width {
  width: 100%; }

.btn [class*="fa-"].icon-left {
  margin: 0 0 0 10px;
  position: relative;
  z-index: 3; }

.border-radius-50 {
  border-radius: 50px; }

.border-radius-30 {
  border-radius: 30px; }
  .border-radius-30.btn-prime .border-btn {
    border-radius: 30px; }

.border-radius-25 {
  border-radius: 25px; }
  .border-radius-25.btn-prime .border-btn {
    border-radius: 25px; }

.border-radius-10 {
  border-radius: 10px; }
  .border-radius-10.btn-prime .border-btn {
    border-radius: 10px; }

.border-radius-5 {
  border-radius: 5px; }
  .border-radius-5.btn-prime .border-btn {
    border-radius: 5px; }

.border-radius-0 {
  border-radius: 0; }
  .border-radius-0.btn-prime .border-btn {
    border-radius: 0; }

.btn-box {
  text-align: center; }
  .btn-box + .btn-box {
    margin-top: 49px; }

.btn {
  /*&:hover {
		&.btn-icon [class*="fa-"]:before {
			color: $gray-dark;
		}
	}*/ }
  .brk-bordered-theme .btn {
    border-radius: var(--b-radius); }
    .brk-bordered-theme .btn.btn-prime .border-btn {
      border-radius: var(--b-radius); }
  .btn:focus {
    box-shadow: none;
    color: #fff; }
  .btn [class*="fa-"] {
    margin-right: 10px;
    vertical-align: baseline; }
    [dir="rtl"] .btn [class*="fa-"] {
      margin-right: 0;
      margin-left: 10px; }
  .btn-icon:before, .btn-icon:after {
    padding-left: 10px; }
  .btn-icon [class*="fa-"] {
    position: relative;
    color: #fff;
    font-size: 16px;
    z-index: 1; }
  .btn-icon-right i {
    position: absolute !important;
    right: 20px;
    margin-right: 0 !important;
    top: 50%;
    transform: translateY(-50%); }
  .btn-icon-right:before, .btn-icon-right:after {
    padding-left: 0; }
  .btn.btn-icon-abs [class*="fa-"] {
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translateY(-50%);
    font-size: 48px;
    color: #e0e0ff;
    opacity: .3;
    z-index: 1; }

.btn-simple {
  font-size: 24px;
  position: relative;
  background: #fff;
  color: var(--brand-primary);
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  min-width: 110px;
  vertical-align: middle;
  line-height: 16px; }
  .btn-simple:hover {
    color: #fff !important;
    background: var(--brand-primary); }
  .btn-simple i.icon-inside {
    font-size: 24px; }
  .btn-simple [class*="fa-"]:not(.icon-inside) {
    margin-right: 0;
    font-size: 24px;
    font-weight: 700; }
  .btn-simple.btn-circle {
    min-width: 0;
    width: 52px;
    height: 52px;
    color: var(--brand-primary) !important; }
    .btn-simple.btn-circle > .before, .btn-simple.btn-circle > .after {
      display: none; }
    .btn-simple.btn-circle:hover {
      background: var(--brk-base-4) !important; }
      .btn-simple.btn-circle:hover .text {
        color: var(--brand-primary) !important; }
    .btn-simple.btn-circle i.icon-inside {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
      [dir="rtl"] .btn-simple.btn-circle i.icon-inside {
        margin-left: 0; }
      .btn-simple.btn-circle i.icon-inside:before {
        line-height: 24px;
        vertical-align: top; }
    .btn-simple.btn-circle.btn-md {
      width: 44px;
      height: 44px; }

.btn-inside-out {
  overflow: hidden;
  border: none;
  transition: background .4s ease .3s, \box-shadow .4s;
  will-change: background; }
  .btn-inside-out_dark {
    background: #272727 !important; }
    .btn-inside-out_dark:hover {
      background: var(--brk-base-4) !important;
      color: #272727 !important; }
      .btn-inside-out_dark:hover i {
        color: #272727 !important; }
    .btn-inside-out_dark > .before,
    .btn-inside-out_dark > .after {
      background: #272727 !important; }
  .btn-inside-out_text-dark {
    color: #272727 !important; }
    .btn-inside-out_text-dark.btn-inside-out-invert.btn-icon [class*="fa-"]:before {
      color: #272727; }
    .btn-inside-out_text-dark > .before,
    .btn-inside-out_text-dark > .after {
      color: #272727 !important; }
  .btn-inside-out_blue {
    background: #000243 !important; }
    .btn-inside-out_blue:hover {
      background-color: var(--brk-base-1) !important;
      color: #ffffff; }
    .btn-inside-out_blue > .before,
    .btn-inside-out_blue > .after {
      background: #000243 !important; }
  .btn-inside-out .icon-inside {
    margin-right: 8px; }
    [dir="rtl"] .btn-inside-out .icon-inside {
      margin-right: 0;
      margin-left: 8px; }
  .btn-inside-out .icon-inside ~ .before, .btn-inside-out .icon-inside ~ .after {
    padding-left: 24px; }
    [dir="rtl"] .btn-inside-out .icon-inside ~ .before, [dir="rtl"] .btn-inside-out .icon-inside ~ .after {
      padding-left: 0;
      padding-right: 24px; }
  .btn-inside-out.btn-icon-right .icon-inside ~ .before,
  .btn-inside-out.btn-icon-right .icon-inside ~ .after,
  .btn-inside-out.btn-icon-right .icon-inside ~ .text {
    padding-right: 20px;
    padding-left: 0; }
    [dir="rtl"] .btn-inside-out.btn-icon-right .icon-inside ~ .before, [dir="rtl"]
    .btn-inside-out.btn-icon-right .icon-inside ~ .after, [dir="rtl"]
    .btn-inside-out.btn-icon-right .icon-inside ~ .text {
      padding-left: 20px;
      padding-right: 0; }
  .btn-inside-out > .before, .btn-inside-out > .after {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    overflow: hidden;
    background: var(--brand-primary);
    color: #fff;
    transition: \transform 0.3s; }
  .btn-inside-out > .before {
    top: 0;
    padding-top: 8px; }
  .btn-inside-out > .after {
    bottom: 0;
    line-height: 0; }
  .btn-inside-out > span.text {
    display: inline-block;
    transform: scale(0.1);
    will-change: transform;
    opacity: 0;
    color: #ffffff;
    transition: \transform 0.3s, opacity 0.3s; }
  .btn-inside-out:hover {
    background: var(--brk-base-1);
    transition: background .4s;
    transition-delay: 0s;
    color: #fff;
    box-shadow: 0 5px 10px -2px var(--brk-base-1); }
    .btn-inside-out:hover > .before {
      transform: translateY(-105%); }
    .btn-inside-out:hover > .after {
      transform: translateY(105%); }
    .btn-inside-out:hover > span.text {
      opacity: 1;
      transform: scale(1); }
    .btn-inside-out:hover.btn-shadow {
      box-shadow: 0 5px 10px -2px rgba(var(--brk-base-1-rgb), 0.8) !important; }
    .btn-inside-out:hover.btn-shadow-small {
      box-shadow: 0 5px 16px rgba(var(--brk-base-1-rgb), 0.8) !important; }
    .btn-inside-out:hover.btn-dark-shadow {
      box-shadow: 0 5px 10px -2px rgba(var(--brk-base-1-rgb), 0.8) !important; }
    .btn-inside-out:hover.btn-dark-shadow-small {
      box-shadow: 0 5px 16px rgba(var(--brk-base-1-rgb), 0.8) !important; }

.btn-inside-out-invert {
  border: none;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.15); }
  .btn-inside-out-invert > .before, .btn-inside-out-invert > .after {
    background: #fff;
    color: var(--brand-primary); }
  .btn-inside-out-invert:hover.btn-icon [class*="fa-"]:before,
  .btn-inside-out-invert:hover span.text {
    color: #fff; }
  .btn-inside-out-invert span.text {
    color: var(--brand-primary); }
  .btn-inside-out-invert.btn-icon [class*="fa-"]:before {
    color: var(--brand-primary); }

.btn-inside-out-invert-light {
  border: none;
  box-shadow: 0 5px 21px var(--brk-base-1); }
  .btn-inside-out-invert-light > .before, .btn-inside-out-invert-light > .after {
    background: #fff;
    color: var(--brand-primary); }
  .btn-inside-out-invert-light:hover {
    background-color: rgba(255, 255, 255, 0.9); }
    .btn-inside-out-invert-light:hover.btn-icon [class*="fa-"]:before {
      color: var(--brand-primary); }
  .btn-inside-out-invert-light span.text {
    color: var(--brand-primary); }
  .btn-inside-out-invert-light.btn-icon [class*="fa-"]:before {
    color: var(--brand-primary); }

.btn-inside-out-light-hover:hover {
  background-color: #ffffff;
  color: #222222; }

.btn-prime {
  color: var(--brand-primary);
  background: #fff;
  border: none;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-prime .border-btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid var(--brand-primary);
    z-index: -1; }
  .btn-prime > .before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 0;
    background: var(--brand-primary);
    transition: background .4s, height .4s;
    z-index: -1; }
  .btn-prime > .after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    background: var(--brand-primary);
    transition: background .4s, height .4s;
    z-index: -1; }
  .btn-prime:hover {
    color: var(--brand-primary) !important; }
    .btn-prime:hover > .before,
    .btn-prime:hover > .after {
      height: 50%;
      background: var(--brk-base-4) !important; }
  .btn-prime-white > .before, .btn-prime-white > .after {
    background: #fff; }
  .btn-prime-white .border-btn {
    border-color: #fff; }
  .btn-prime-white:hover {
    color: var(--brand-primary) !important; }
  .btn-prime-white-transparent {
    background: transparent;
    color: #fff; }
    .btn-prime-white-transparent:hover > .before,
    .btn-prime-white-transparent:hover > .after {
      background: #fff !important; }
    .btn-prime-white-transparent .border-btn {
      border-color: #fff; }
  .btn-prime-primary-transparent {
    background: transparent;
    color: #fff; }
    .btn-prime-primary-transparent:hover > .before,
    .btn-prime-primary-transparent:hover > .after {
      background: #fff !important; }
    .btn-prime-primary-transparent .border-btn {
      border-color: var(--brand-primary); }
  .btn-prime-primary-text-transparent {
    background: transparent;
    color: var(--brand-primary); }
    .btn-prime-primary-text-transparent:hover > .before,
    .btn-prime-primary-text-transparent:hover > .after {
      background: #fff !important; }
    .btn-prime-primary-text-transparent .border-btn {
      border-color: var(--brand-primary); }
  .btn-prime.btn-invert {
    color: #fff; }
    .btn-prime.btn-invert > .before, .btn-prime.btn-invert > .after {
      background: transparent; }
    .btn-prime.btn-invert .border-btn {
      border-color: var(--brk-base-2); }
  .btn-prime_thin .border-btn {
    border-width: 1px; }

.all-light .btn-prime {
  background: transparent;
  color: #fff;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.3); }
  .all-light .btn-prime:hover [class*="fa-"].icon-left {
    color: #fff; }
  .all-light .btn-prime [class*="fa-"].icon-left {
    color: var(--brand-primary);
    font-size: 24px;
    vertical-align: sub;
    float: right;
    line-height: 1;
    margin: -2px 6px 0 0; }

.btn-gradient {
  background: var(--brand-primary);
  overflow: hidden;
  position: relative;
  appearance: none;
  border: none;
  color: #fff;
  --x:190px;
  --y:30px;
  --size: 200px;
  transition: background .3s; }
  .btn-gradient span,
  .btn-gradient i {
    z-index: 1;
    position: relative;
    pointer-events: none; }
  .btn-gradient::before {
    content: '';
    position: absolute;
    left: var(--x);
    top: var(--y);
    width: var(--size);
    height: var(--size);
    background: radial-gradient(circle closest-side, var(--secondary), transparent);
    transform: translate(-50%, -50%);
    transition: width .2s, height .2s;
    z-index: 0; }
  .btn-gradient:hover {
    color: #ffffff; }
  .btn-gradient:hover::before {
    --size: 200px; }

.btn-pos {
  color: #333;
  background: #F6F6F6;
  overflow: hidden;
  position: relative; }
  .btn-pos span {
    position: absolute;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    border-radius: 50%;
    background-color: var(--brand-primary);
    transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1; }
  .btn-pos:hover {
    color: #fff; }
    .btn-pos:hover span {
      width: 250%;
      height: 250%; }

.icon__btn {
  display: inline-block;
  margin: 10px;
  border-radius: 50%;
  color: #fff;
  background: var(--brand-primary);
  position: relative;
  z-index: 3; }
  .brk-bordered-theme .icon__btn {
    border-radius: var(--b-radius); }
  .icon__btn .spike {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    background: var(--brand-primary);
    z-index: -1; }
    .icon__btn .spike:nth-child(1) {
      transform: rotate(0deg); }
    .icon__btn .spike:nth-child(2) {
      transform: rotate(10deg); }
    .icon__btn .spike:nth-child(3) {
      transform: rotate(20deg); }
    .icon__btn .spike:nth-child(4) {
      transform: rotate(30deg); }
    .icon__btn .spike:nth-child(5) {
      transform: rotate(40deg); }
    .icon__btn .spike:nth-child(6) {
      transform: rotate(50deg); }
    .icon__btn .spike:nth-child(7) {
      transform: rotate(60deg); }
    .icon__btn .spike:nth-child(8) {
      transform: rotate(70deg); }
    .icon__btn .spike:nth-child(9) {
      transform: rotate(80deg); }
    .icon__btn .spike:nth-child(10) {
      transform: rotate(90deg); }
  .icon__btn > .before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scale(1.35);
    opacity: .1;
    background-color: var(--brand-primary);
    border-radius: 50%;
    z-index: -1; }
    .brk-bordered-theme .icon__btn > .before {
      border-radius: var(--b-radius); }
  .icon__btn > .after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .2;
    background-color: var(--brand-primary);
    border-radius: 50%;
    z-index: -1; }
    .brk-bordered-theme .icon__btn > .after {
      border-radius: var(--b-radius); }
  .icon__btn_clear > .before {
    transform: scale(1); }
  .icon__btn:hover {
    color: #fff; }
    .icon__btn:hover > .before {
      animation: 1.5s brk-pulse .3s infinite; }
    .icon__btn:hover > .after {
      animation: brk-pulse 1.5s infinite; }
  .icon__btn-circled > .before {
    top: -33px;
    left: -33px;
    right: -33px;
    bottom: -33px;
    transform: scale(1); }
  .icon__btn-circled > .after {
    top: -16px;
    left: -16px;
    right: -16px;
    bottom: -16px; }
  .icon__btn-circled:hover > .before {
    animation: 1s brk-pulse-paused .2s ease-in infinite; }
  .icon__btn-circled:hover > .after {
    animation: brk-pulse-paused 1s ease-in infinite; }
  .icon__btn-circled_active > .before {
    animation: 1s brk-pulse-paused .2s ease-in infinite; }
  .icon__btn-circled_active > .after {
    animation: brk-pulse-paused 1s ease-in infinite; }
  .icon__btn i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .icon__btn-invert {
    background: #fff;
    color: var(--brand-primary); }
    .icon__btn-invert > .bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      border-radius: 100%;
      background: #fff; }
      .brk-bordered-theme .icon__btn-invert > .bg {
        border-radius: var(--b-radius); }
    .icon__btn-invert:hover {
      background: #fff;
      color: var(--brand-primary); }
  .icon__btn-white {
    background: #fff;
    color: var(--brand-primary); }
    .icon__btn-white > .before {
      background-color: #fff; }
    .icon__btn-white > .after {
      background-color: #fff; }
    .icon__btn-white:hover {
      background: #fff;
      color: var(--brand-primary); }
  .icon__btn-sm {
    font-size: 14px;
    width: 41px;
    height: 41px; }
  .icon__btn-md {
    font-size: 16px;
    width: 52px;
    height: 52px; }
  .icon__btn-md_1 {
    font-size: 26px;
    width: 70px;
    height: 70px; }
  .icon__btn-lg {
    font-size: 21px;
    width: 68px;
    height: 68px; }
  .icon__btn-xl {
    font-size: 21px;
    width: 86px;
    height: 86px; }
  .icon__btn-center [class*="fa-"] {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    text-align: center; }
  .icon__btn.icon__btn-svg {
    background: transparent !important; }

@keyframes brk-pulse {
  0% {
    transform: scale(1); }
  50% {
    transform: scale(2); }
  100% {
    transform: scale(1);
    opacity: 0; } }

@keyframes brk-pulse-paused {
  0% {
    transform: scale(1); }
  20% {
    transform: scale(1.2); }
  60% {
    transform: scale(0.9);
    opacity: 0; }
  100% {
    transform: scale(1); } }

@keyframes lightning {
  0% {
    left: -100px;
    z-index: 3; }
  50% {
    left: 300%; }
  100% {
    left: -100px;
    z-index: 0; } }

@-webkit-keyframes lightning {
  0% {
    left: -100px;
    z-index: 3; }
  50% {
    left: 100%; }
  100% {
    left: -100px;
    z-index: 0; } }

@keyframes wave {
  0% {
    margin-left: 0; }
  100% {
    margin-left: -9999px; } }

.btn__dropdown {
  text-align: center; }
  .btn__dropdown_wrap {
    position: relative;
    display: inline-block;
    padding-right: 41px;
    list-style: none;
    min-width: 170px;
    padding-left: 0;
    margin-bottom: 0;
    overflow: hidden;
    transition: all 300ms; }
    .brk-bordered-theme .btn__dropdown_wrap {
      border-radius: var(--b-radius); }
    .btn__dropdown_wrap:not(.open) {
      height: 41px; }
    .btn__dropdown_wrap:hover {
      cursor: pointer; }
    .btn__dropdown_wrap.btn__primary {
      background-color: var(--brand-primary); }
    .btn__dropdown_wrap.btn__white {
      background-color: #eff5ff;
      color: var(--brand-primary);
      box-shadow: 0 5px 21px var(--brk-base-1); }
    .btn__dropdown_wrap:before {
      position: absolute;
      font-family: 'Font Awesome\ 5 Free';
      font-size: 13px;
      content: '\f0dd';
      top: calc(50% - 2px);
      right: 19px;
      line-height: 1;
      transform: translateY(-50%);
      font-weight: 600; }
    .btn__dropdown_wrap.open {
      transition: all 300ms; }
      .btn__dropdown_wrap.open:before {
        content: '\f0de';
        top: 50%; }
    .btn__dropdown_wrap button:hover, .btn__dropdown_wrap button:focus {
      background: none !important; }
  .btn__dropdown_item {
    border-bottom: 1px solid;
    border-right: 1px solid;
    padding-left: 18px;
    text-align: left; }
    .btn__primary .btn__dropdown_item {
      border-color: var(--brk-base-3);
      background: var(--brand-primary); }
      .btn__primary .btn__dropdown_item a, .btn__primary .btn__dropdown_item button {
        color: #ffffff; }
        .btn__primary .btn__dropdown_item a:hover, .btn__primary .btn__dropdown_item button:hover {
          color: #ffffff; }
    .btn__white .btn__dropdown_item {
      border-color: #dee9fc;
      background: #fff; }
      .btn__white .btn__dropdown_item a, .btn__white .btn__dropdown_item button {
        color: var(--brand-primary); }
        .btn__white .btn__dropdown_item a:hover, .btn__white .btn__dropdown_item button:hover {
          color: var(--brand-primary); }
    .btn__dropdown_item:first-child {
      padding-top: 2px; }
    .btn__dropdown_item:last-child {
      border-bottom: none; }
  .btn__dropdown_btn {
    text-transform: uppercase;
    font-weight: inherit;
    text-align: left;
    font-size: 14px;
    padding: 9px 9px;
    margin: 0;
    border: none;
    background-color: transparent; }
    .btn__dropdown_item .btn__dropdown_btn {
      box-shadow: none; }

.margin-box__btn-round {
  margin: 25px 0; }
  @media (min-width: 768px) {
    .margin-box__btn-round {
      margin: 0; } }

.margin-box__btn-dropdown {
  margin-top: 30px;
  margin-bottom: 1em; }
  @media (min-width: 768px) {
    .margin-box__btn-dropdown {
      margin-bottom: 63px; } }
  @media (max-width: 767px) {
    .col-lg-3:first-child .margin-box__btn-dropdown {
      margin-top: 0; } }

.btn--square {
  border: 2px solid var(--brand-primary);
  border-radius: 0;
  padding: 12px 42px;
  overflow: hidden;
  margin: 0; }
  .btn--square .angle {
    width: 10px;
    height: 300%;
    position: absolute;
    display: block;
    transform: translate(-50%, -50%) rotate(-45deg);
    top: 0;
    left: 100%;
    transition: top .2s, left .2s; }
  .btn--square:hover .angle {
    top: 3%;
    left: 97%; }

.btn-backgrounds {
  display: inline-block;
  min-width: 260px;
  height: 56px;
  border-radius: 28px;
  background-color: #fff;
  position: relative;
  letter-spacing: -0.06em;
  padding-right: 25px;
  font-size: 0.8125rem;
  line-height: 56px; }
  .btn-backgrounds .before {
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding-right: 23px;
    letter-spacing: -0.06em; }
    .brk-bordered-theme .btn-backgrounds .before {
      border-radius: var(--b-radius); }
    .btn-backgrounds .before i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      vertical-align: 0;
      font-size: 1.125rem; }
  .brk-bordered-theme .btn-backgrounds {
    border-radius: var(--b-radius); }
  .btn-backgrounds_wauto {
    min-width: 1px;
    white-space: nowrap; }
  .btn-backgrounds_md {
    height: 46px;
    line-height: 46px; }
  .btn-backgrounds_280 {
    min-width: 280px; }
  .btn-backgrounds_200 {
    min-width: 200px; }
  .btn-backgrounds_white {
    box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
    color: #272727; }
    .btn-backgrounds_white .before {
      background-color: #eee; }
  .btn-backgrounds_dark {
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.3);
    color: #fff; }
    .btn-backgrounds_dark .before {
      border: solid 2px rgba(255, 255, 255, 0.4); }
      .btn-backgrounds_dark .before i {
        color: #fff; }
  .btn-backgrounds_dark-classic {
    background-color: var(--brand-primary);
    color: #ffffff;
    overflow: hidden; }
    .btn-backgrounds_dark-classic:before, .btn-backgrounds_dark-classic:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.1);
      height: 50%;
      z-index: 0; }
    .btn-backgrounds_dark-classic:before {
      top: -100%;
      transition: top .3s; }
    .btn-backgrounds_dark-classic:after {
      bottom: -100%;
      transition: bottom .3s; }
    .btn-backgrounds_dark-classic .text {
      position: relative;
      z-index: 2; }
    .btn-backgrounds_dark-classic:hover {
      color: #ffffff; }
      .btn-backgrounds_dark-classic:hover:before {
        top: 0; }
      .btn-backgrounds_dark-classic:hover:after {
        bottom: 0; }
    .btn-backgrounds_dark-classic:focus, .btn-backgrounds_dark-classic:active {
      color: #ffffff; }
    .btn-backgrounds_dark-classic .before {
      width: 34px;
      height: 34px;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 2; }
      .btn-backgrounds_dark-classic .before i {
        font-size: 0.9375rem; }
  .btn-backgrounds_white-classic {
    background-color: #fff;
    color: #272727;
    overflow: hidden; }
    .btn-backgrounds_white-classic:before, .btn-backgrounds_white-classic:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      background-color: rgba(0, 0, 0, 0.05);
      height: 50%;
      z-index: 0; }
    .btn-backgrounds_white-classic:before {
      top: -100%;
      transition: top .3s; }
    .btn-backgrounds_white-classic:after {
      bottom: -100%;
      transition: bottom .3s; }
    .btn-backgrounds_white-classic .text {
      position: relative;
      z-index: 2; }
    .btn-backgrounds_white-classic:hover {
      color: #272727; }
      .btn-backgrounds_white-classic:hover:before {
        top: 0; }
      .btn-backgrounds_white-classic:hover:after {
        bottom: 0; }
    .btn-backgrounds_white-classic:focus, .btn-backgrounds_white-classic:active {
      color: #272727; }
    .btn-backgrounds_white-classic .before {
      width: 34px;
      height: 34px;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 2; }
      .btn-backgrounds_white-classic .before i {
        font-size: 0.9375rem; }
  .btn-backgrounds_transparent {
    background-color: transparent;
    box-shadow: none;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.16); }
    .btn-backgrounds_transparent .before {
      background-color: #fff; }
      .btn-backgrounds_transparent .before i {
        color: var(--brand-primary); }
    .btn-backgrounds_transparent:hover {
      color: #fff;
      text-decoration: underline; }
  .btn-backgrounds_left-icon {
    padding-right: 0;
    padding-left: 25px; }
    .btn-backgrounds_left-icon .before {
      right: auto;
      left: 7px; }
  .btn-backgrounds_right-icon {
    padding-right: 25px;
    padding-left: 0; }
  .btn-backgrounds_no-icon {
    padding-left: 25px;
    padding-right: 25px;
    text-align: center; }

.btn-bottom-position {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  margin: 0; }

.btn-violet {
  font-size: 0.75rem;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1) !important; }
  .btn-violet:hover {
    background: rgba(114, 2, 187, 0.05); }
  .btn-violet .before,
  .btn-violet .after {
    padding-left: 0 !important;
    padding-right: 10px;
    color: var(--secondary); }
  .btn-violet .text {
    color: var(--secondary) !important;
    margin-right: 10px; }
  .btn-violet i {
    position: absolute !important;
    top: 50%;
    right: 20px;
    margin-right: 0 !important;
    transform: translateY(-50%); }
    .btn-violet i:before {
      color: var(--secondary) !important; }
